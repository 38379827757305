import React, { FC } from "react";
import { theme } from "flicket-ui";
import { ThemeProvider } from "styled-components";

import {
  BaseProvider,
  UserProvider,
  ReservationProvider,
  TicketNamingProvider,
  ShopifyProvider,
} from "~context";
import { CurrentOrganizationQuery } from "~graphql/sdk";
import { ReCaptchaProvider } from "~context/recaptchaContext";
import ErrorBoundary from "~components/errorBoundary";

export const Providers: FC<{
  organization: CurrentOrganizationQuery["currentOrganization"];
}> = ({ children, organization }) => {
  const organizationColors = {
    ...(organization?.branding?.N100 && { N100: organization.branding.N100 }),
    ...(organization?.branding?.N200 && { N200: organization.branding.N200 }),
    ...(organization?.branding?.N300 && { N300: organization.branding.N300 }),
    ...(organization?.branding?.N400 && { N400: organization.branding.N400 }),
    ...(organization?.branding?.N500 && { N500: organization.branding.N500 }),
    ...(organization?.branding?.N600 && { N600: organization.branding.N600 }),
    ...(organization?.branding?.N700 && { N700: organization.branding.N700 }),
    ...(organization?.branding?.N800 && { N800: organization.branding.N800 }),
    ...(organization?.branding?.P100 && { P100: organization.branding.P100 }),
    ...(organization?.branding?.P200 && { P200: organization.branding.P200 }),
    ...(organization?.branding?.P300 && { P300: organization.branding.P300 }),
    ...(organization?.branding?.S100 && { S100: organization.branding.S100 }),
    ...(organization?.branding?.S200 && { S200: organization.branding.S200 }),
    ...(organization?.branding?.S300 && { S300: organization.branding.S300 }),
  };

  const brandedTheme = {
    ...theme,
    fonts: {
      ...theme.fonts,
      body: "Figtree, sans-serif",
      heading: "Figtree, sans-serif",
    },
    colors: {
      ...theme.colors,
      ...organizationColors,
      link: "#124A6C",
      P300: "#F06F05",
      P200: "#f06f0550",
      P100: "#f06f0525",
      error: "#DC3009",
      error25: "#fdf5f3",
      error50: "#DC3009",
      error100: "#DC3009",
      N800: "#1A1A1A",
      N700: "#404040",
      N600: "#666666",
      N500: "#999999",
      N400: "#B2B2B2",
      N300: "#CCCCCC",
      N200: "#E5E5E5",
      N100: "#F2F2F2",
    },
  };

  const recaptchaKey = process.env.RECAPTCHA_SITE_KEY;

  return (
    <ErrorBoundary organization={organization}>
      <BaseProvider organization={organization}>
        <ThemeProvider theme={brandedTheme}>
          <UserProvider>
            <ReservationProvider>
              <ShopifyProvider>
                <TicketNamingProvider>
                  <ReCaptchaProvider reCaptchaKey={recaptchaKey}>
                    {children}
                  </ReCaptchaProvider>
                </TicketNamingProvider>
              </ShopifyProvider>
            </ReservationProvider>
          </UserProvider>
        </ThemeProvider>
      </BaseProvider>
    </ErrorBoundary>
  );
};
